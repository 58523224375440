<template>
    <div class="msp-test-attivi">

    <msp-app-bar title="Test attivi"></msp-app-bar>

    <v-container>
    <v-btn class="mb-3" @click="addTest" block color="primary">Aggiungi un test</v-btn>

    
    <div class="test-attivi__container">
    <div class="test-sport" :style="'--color:'+sport.colore" v-for="sport in tests" :key="sport.id">
    <div class="test-attivo" v-for="test in sport.tests" :key="test.id">
    <test-dettaglio :test="test"> </test-dettaglio>
    <zone mode="view" :sport_id="test.sport_id" :um_id="test.um_id" :um_descrizione="test.um_descrizione"  :value="test.zone" debug="zone attive"></zone>
    </div>
    </div>

    </div>
    
</v-container>
<msp-app-footer></msp-app-footer>
</div>
</template>
    


<script>
import moment from 'moment';
import test from "@mixins/test.js";
import env from "@mixins/environment.js"
import api from "@mixins/api.js";
import constants from "@src/js/msp/constants.js"
import zone from '@src/js/vue/include/test/Zone.vue'
import testDettaglio from '@components/testDettaglio.vue'
import { mapGetters } from 'vuex'
import mspAppBar from "@components/MspAppBar.vue";
import mspAppFooter from "@components/MspAppFooter.vue";
import _sports from "@cached/sport.json"

const component = {

    components: {
        zone,
        mspAppBar,
        mspAppFooter,
        testDettaglio,
    },
    mixins: [
    test,
    env,
    ],
    data: function () {
        return {
            sport_id: 0,
            zone: [],
            tests: []
        }
    },

    computed: {
    ...mapGetters('atleti', [
      'atleta',
    ]),
    ...mapGetters('utente', [
      'utente',
    ]),


    },
    watch: {
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init: function () {
            this.loadTests();
        },
        update: function ()
        {
            this.loadTests();
        },


  addTest: async function () {
    this.$router.push("/calendario/");
    this.$store.dispatch("calendario/setDialog",true);
    this.$router.push("/calendario/test/add");
  },



        sortTestBySport: function (tests) {
            return tests.sort((a, b)=>{
                const sport_a = _sports.find(el=>el.id===a.sport_id);
                const sport_b = _sports.find(el=>el.id===b.sport_id);
                const pos_a = sport_a.posizione;
                const pos_b = sport_b.posizione;
                return (pos_a < pos_b) ? -1 : 1;

            });
        },

        sortTestByTipo: function (tests) {
            let tipi = ['passo', 'potenza', 'cardio'];
            return tests.sort((a, b)=>{
                let sorted = tipi.indexOf(a.um_tipo_codice) - tipi.indexOf(b.um_tipo_codice);
                return sorted;
            });
        },



        loadTestsSport: async function (atleta_id, data, sport_id,  tipo) {
            let url = this.api_base_url + `/api/v2/risultato.php?attivo&dettaglio=1&sport_id=${sport_id}&data=${data}&atleta_id=${atleta_id}&tipo=${tipo}`;
            const result = await api(url, 'GET');
            if(result.success) {

                return result.data;
            } else {

                return null;
            }
        },

        loadTests: async function () {
            this.tests = [];
            if (!this.atleta) return;
            let oggi = moment(new Date()).format('Y-MM-DD HH:mm:ss');
            let sports = [
            {
                sport_id: constants.sport_id.podismo,
                tipo: 'cardio'
            },
            {
                sport_id: constants.sport_id.podismo,
                tipo: 'passo'
            },
            {
                sport_id: constants.sport_id.ciclismo,
                tipo: 'cardio'
            },
            {
                sport_id: constants.sport_id.ciclismo,
                tipo: 'potenza'
            },
            {
                sport_id: constants.sport_id.nuoto,
                tipo: 'cardio'
            },
            {
                sport_id: constants.sport_id.nuoto,
                tipo: 'passo'
            },
            ];

            let filteredSports;
            if (this.filterSportIds) {
                filteredSports = sports.filter(el=>this.filterSportIds.indexOf(el.sport_id)!==-1);
            } else {
                filteredSports=sports;
            }
            let tmp_tests = [];
            let atleta_id = this.atleta.id;
            for (let i = 0; i < filteredSports.length; i++) {
                let el = filteredSports[i];
                let result = await this.loadTestsSport(atleta_id, oggi, +el.sport_id, el.tipo);
                if(result) {
                    tmp_tests.push(result);
                }
            }
            tmp_tests = this.sortTestBySport(tmp_tests);
            this.tests = [];
            let currentSport = -1;
            let index = -1;
            tmp_tests.forEach((test)=>{
                if (+test.sport_id !== +currentSport) {
                    currentSport = test.sport_id;
                    index += 1;
                    let sport = {
                        sport_id: test.sport_id,
                        sport_descrizione: test.sport_descrizione,
                        sport_codice: test.sport_codice,
                        tests: []
                    }
                    this.tests.push(sport);
                }
                this.tests[index].tests.push(test);
            })
            //ordina i test per tipo (passo, potenza, cardio)
            if (this.tests && this.tests.length)
            this.tests.forEach(test=>{
                test.tests = this.sortTestByTipo(test.tests);
            });
        }


    }
}
        export default component;
</script>

    <style lang="scss">
    .msp-test-attivi {
        .test-attivo {
        margin-bottom: 20px;
    }
.test-attivi__title {
    background: var(--color);
    i {
        font-size: 2em;
    }
}
    }
    </style>


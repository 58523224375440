<template>
  <div class="msp-allenamento-item">
    <v-card
    outlined
    @click="clickItem"
    >

    <v-list-item three-line
    >

    <v-list-item-avatar
    tile
    >
    <i class="ev-all__icon " :class="'msp-icon-sport-'+allenamento.sport_id" alt=""></i>

  </v-list-item-avatar>


  <v-list-item-content>
    <v-rating
    length="5"
    readonly
    half-increments
    v-if="hasRating(allenamento.sport_id)"
    :value="+allenamento.rating / 2"
    size="12"
    class="overline"
    >
  </v-rating>
  <h2 v-if="!isRiposo">{{allenamento.titolo}}</h2>
  <h2 v-else>Riposo</h2>
  <div class="totalone" v-if="allenamento.totalone">

    <template v-if="isPodismo(allenamento.sport_id)" >
      <span class="ev-all__icon--time msp-icon-time" aria-hidden="true"></span>
      <span class="ev-all__content--time">{{formatoRisultato(allenamento.totalone.sec, 'sec')}}</span>
      <span class="ev-all__icon--distance msp-icon-distance" aria-hidden="true"></span>
      <span class="ev-all__content--distance">{{formatoRisultato(allenamento.totalone.mt, 'km')}}</span>
      </template>

      <template v-if="isSci(allenamento.sport_id)" >
      <span class="ev-all__icon--time msp-icon-time" aria-hidden="true"></span>
      <span class="ev-all__content--time">{{formatoRisultato(allenamento.totalone.sec, 'sec')}}</span>
    </template>

    <template v-if="isCiclismo(allenamento.sport_id)">
      <span class="ev-all__icon--time msp-icon-time" aria-hidden="true"></span>
      <span class="ev-all__content--time">{{formatoRisultato(allenamento.totalone.sec, 'sec')}}</span>
    </template>

    <template v-if="isNuoto(allenamento.sport_id)">
      <span class="ev-all__icon--time msp-icon-time" aria-hidden="true"></span>
      <span class="ev-all__content--time">{{formatoRisultato(allenamento.totalone.sec, 'sec')}}</span>
      <span class="ev-all__icon--distance msp-icon-distance" aria-hidden="true"></span>
      <span class="ev-all__content--distance">{{formatoRisultato(allenamento.totalone.mt, 'mt')}}</span>
    </template>

    <template v-if="isPalestra(allenamento.sport_id)">
      <span class="ev-all__icon--time msp-icon-time" aria-hidden="true"></span>
      <span class="ev-all__content--time">{{formatoRisultato(allenamento.totalone.sec, 'sec')}}</span>
    </template>
  </div>


</v-list-item-content>
</v-list-item>
<div v-if="hasGraficoAndature(allenamento)" class="grafico-andature">
        <msp-grafico-andature-semplice
            v-bind = "allenamento.totalone.grafico"
            :colore = "false"
        />
</div>

<v-card-actions v-if="allenamentoHasFooter(allenamento)">

  <v-btn v-if="allenamentoHasSlotNote(allenamento)" class="slot__note" text :class="classNote" small @click.prevent.stop="openChatCalendario(allenamento.calendario_id)">
      <v-icon class="icon-feedback note">{{iconaCommenti}}</v-icon><span class='count' v-if="countCommenti"><span class="small">x</span>{{countCommenti}}</span>
  </v-btn>
  <v-btn :disabled="!allenamento.attivita_id" :style="stylePercentuale" class="slot__percentuale" v-if="allenamentoHasSlotPercentuale(allenamento)" text small @click.prevent.stop="openAttivita(allenamento.attivita_id)"><i class="msp-icon-graph"></i><span  class="icon-feedback percentuale" > {{allenamento.attivita.percRaggiungimento}} </span></v-btn>
  <span class="icon-feedback rpe slot__rpe" v-if="valutazione && allenamentoHasSlotRpe(allenamento)" >
      <span class="icon-feedback valutazione" ><i :style="'--color: '+valutazione.colore"  :class="valutazione.icona"/></span>

            <span v-if="allenamento.feedback_rpe" v-text="allenamento.feedback_rpe"></span> 
       </span>
  <span :class="classBests" class="icon-feedback bests slot__bests" v-if="countBests" >
      <span class="icon-feedback bests" ><i class="icon-bests msp-icon-award"/></span>

      <span class="small">x</span> {{countBests}} </span>
  
</v-card-actions>
</v-card>
</div>
</template>

<script>
// ( emoji + rpe / commenti / % ragg. / record )
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import allenamentoFooter from "@src/js/vue/mixins/allenamentoFooter.js";
import valutazione from "@cached/valutazione_allenamento.json"
import MspGraficoAndatureSemplice from './MspGraficoAndatureSemplice.vue';
import _funzioni from "@src/js/msp/funzioni.js";
import _percentuali from "@cached/percentuali.json";
function getColor (percentuale) {
    let elCol = _percentuali.find(el=>(el.min<percentuale && el.max >= percentuale));
    return elCol ? elCol.col : "#ccc";
}

const component = {
  components: {
    MspGraficoAndatureSemplice,

  },
  mixins: [
    allenamentoCalcoli,
    allenamentoFooter,
  ],
  props: [
    'allenamento'
  ],
  computed: {
      stylePercentuale: function () {
          if (!_funzioni.deepProp(this.allenamento,'attivita.percRaggiungimento')) {

              return "";
          }
          const color = getColor(this.allenamento.attivita.percRaggiungimento);
          return `--percentuale-color: ${color}`;
      },
      countBests: function () {
          return this.allenamento.bests && this.allenamento.bests.length;
      },
      classBests: function () {
          if (!this.allenamento) {
              return "";
          }
          if (!_funzioni.deepProp(this.allenamento,'bests.length')) {
              return "";
          }
          let first = this.allenamento.bests.filter(el=>el.posizione == 1);
          if (first.length) {
              return "gold";
          }
          return "";
      },

      valutazione: function () {
          let valutazione_id = (this.allenamento.feedback_valutazione) ? this.allenamento.feedback_valutazione : 0;
          let valutazioneAtleta = valutazione.find(el=>el.id==valutazione_id);
          return valutazioneAtleta;
      },
      countCommentiNuovi: function () {
          return this.allenamento.chat_count_nuovi;
      },
      countCommenti: function () {
          return this.allenamento.chat_count_totale;
      },
      iconaCommenti: function () {
          if (this.countCommentiNuovi) {
              return "mdi-chat";
          }
          return "mdi-chat-outline";
      },
      classNote: function () {
          if (this.countCommentiNuovi) {
              return "nuovi";
          }

          return "";
      },
      isRiposo: function ()
      {
          if (!this.allenamento)
          {
              return false;
          }
          return this.allenamento.sport_id === this.MSP().ID_RIPOSO
      },


      classAlert: function ()
      {
          if (!this.allenamento)
          {
              return "";
          }
          if (+this.allenamento.alert)
          {
              return "active";
          }
          return "";
      },
      classAllenamento: function ()
      {
          if (!this.allenamento)
          {
              return "";
          }
          let a_ca = [];
          a_ca.push("allenamento-sport-"+this.allenamento.sport_id);

          if (this.selected)
          {
              a_ca.push('selected');
          }

          if (this.ghost)
          {
              a_ca.push('ghost');
          }
          if (this.over)
          {
              a_ca.push('over');
          }
          if (!this.allenamento.risultato_id)
          {
              a_ca.push('hasNotRisultato');
          }
          if (this.hasMenu)
          {
              a_ca.push('has-menu');
          }

          return a_ca.join(" ");
      }
  },
  mounted() {
  },
  methods: {
    clickItem: function ()
    {
      this.$emit("click", this.allenamento);
    },
    openAttivita: function (attivita_id) {
        if (!attivita_id) return;
      this.$emit("open-attivita", attivita_id);
    },
    openChatCalendario: function (calendario_id) {
      this.$emit("open-chat-calendario", calendario_id);
    },
    formatoRisultato: function(val, um) {
      if (um === 'sec') {
        return this.MSP().sec2hms(val);
      } else {
        if (this.allenamento.sport_id == this.MSP().sport_id.nuoto) {
          return Math.round(val) + " mt";
        } else {
          return (val/1000).toFixed(2) + " km";

        }
      }
    },

  }
}
export default component;
</script>

<style lang="scss">
.msp-allenamento-item {
    .count {
        display: inline-block;
        background: #fff;
        color: #000;
        padding: 2px;
        margin-left: 5px;
        border-radius: 3px;
    }
    .excerpt {
        white-space: nowrap;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .v-rating button {
        padding: 0;
    }
    .v-avatar {
        width: var(--avatar-size);
        height: var(--avatar-size);
        min-height: var(--avatar-size);
        i {
            font-size: var(--avatar-size);
        }
    }
    h2 {
        font-size: 1em;
    }
    [class^="ev-all__icon"] {
        font-size: 1em;
    }

    .totalone {
        display: flex;
        align-items: center;
        &>span {
            margin: 0 5px 0 0;
        }
    }
    .v-card__actions {
        display: grid;
        position: relative;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "rpe note percentuale bests";
    }
    .slot {
        &__note {
            grid-area: note;
            justify-content: stretch;
            align-items: stretch;
            --color: var(--col-grigio);
            &.nuovi .v-icon {
                --color: var(--col-msp);
            }
            .v-icon {
                color: var(--color);
            }
        }
        &__rpe {
            grid-area: rpe;
        }
        &__bests {
            grid-area: bests;
        }
        &__valutazione {
            grid-area: valutazione;
        }
        &__percentuale {
            color: var(--percentuale-color);
            grid-area: percentuale;
            justify-content: stretch;
            align-items: stretch;
        }
    }
    .icon-feedback {
        margin: 0 5px;
        &.rpe {
            display: flex;
            align-items: center;
            i {
                font-size: 1em;
            }
        }

        &.bests {
            display: flex;
            align-items: center;
        }

        &.valutazione i {
            font-size: 20px;
            align-content: center;
            background: var(--color);
            border-radius: 50px;
            display: grid;
            height: 1em;
            justify-content: center;
            width: 1em;
            font-size: 1em;
        }
    }
    .grafico-andature {
        height: 50px;
    }
    .placeholder {
        filter: grayscale(1);
        opacity: 0.3;
    }

    .valutazione {
        grid-area: valutazione;
    }

    .rpe {
        grid-area: rpe;
    }

    .percentuale {
        grid-area: percentuale;
    }

    .note {
        grid-area: note;
    }
    .icon-bests {
        --ico-color: var(--col-grigio);
    }
    .small {
        font-size: 0.8em;
    }
    .gold .icon-bests {
        --ico-color: gold;
    }
}
</style>
